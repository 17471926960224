import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { IPixelContext } from '@app/objects/PixelContext';
import { RoutePaths } from '@app/utilities/RoutePathVars';

export function usePixel(): IPixelContext {
	const location = useLocation();
	const [requestQuoteZip, setRequestQuoteZip] = React.useState<string>('');

	React.useEffect(() => {
		if (location.pathname !== RoutePaths.requestQuotePage && requestQuoteZip) setRequestQuoteZip('');
	}, [location.pathname, requestQuoteZip]);

	return React.useMemo(() => ({
		requestQuoteZip,
		setRequestQuoteZip,
	}), [
		requestQuoteZip,
	]);
}
