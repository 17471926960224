import * as React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';

import { Header } from '@app/components/Header/Header';
import { Footer } from '@app/components/Footer/Footer';
import { CookiesAlert } from '@app/components/CookiesAlert/CookiesAlert';
import { useAoTracker } from '@app/hooks/useAoTracker';
import { BurgerContext, PixelContext } from '@app/services/contexts';
import { useBurger } from '@app/hooks/useBurger';
import { ContactsWidget } from '@app/components/ContactsWidget/ContactsWidget';
import { PixelTracking } from '@app/components/PixelTracking/PixelTracking';
import { usePixel } from '@app/hooks/usePixel';

interface LayoutProps {
	children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
	useAoTracker(); // Needs to be placed under <Router />
	const context = useBurger();
	const pixelContext = usePixel();
	const location = useLocation();
	const hideHeaderFooter = location.pathname === '/airGateway';

	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.app.recaptchaKey}>
			<PixelContext.Provider value={pixelContext}>
				<BurgerContext.Provider value={context}>
					<PixelTracking />
					{!hideHeaderFooter && <Header />}
					{props.children}
					{!hideHeaderFooter && <Footer />}
					<CookiesAlert />
					{!hideHeaderFooter && <ContactsWidget />}
				</BurgerContext.Provider>
			</PixelContext.Provider>
		</GoogleReCaptchaProvider>
	);
};
