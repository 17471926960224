import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
} from 'react-router-dom';

import { AirGateway } from './pages/AirGateway/AirGateway';

import { Layout } from '@app/layout/Layout';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import { NotFound } from '@app/pages/StaticPages/NotFound/NotFound';
import { MainPage } from '@app/pages/MainPage/MainPage';
import { SearchPackagesPage } from '@app/pages/SearchPage/SearchPackagesPage';

import { GeneralTerms } from '@app/pages/StaticPages/GeneralTerms/GeneralTerms';
import { PrivacyPolicy } from '@app/pages/StaticPages/PrivacyPolicy/PrivacyPolicy';
import { WhyChooseOVC } from '@app/pages/StaticPages/WhyChooseOVC/WhyChooseOVC';
import { TravelInsurance } from '@app/pages/StaticPages/TravelInsurance/TravelInsurance';
import { DocumentationRequirements } from '@app/pages/StaticPages/DocumentationRequirements/DocumentationRequirements';
import { Managers } from '@app/pages/StaticPages/Managers/Managers';
import { FAQ } from '@app/pages/StaticPages/FAQ/FAQ';
import { AboutUs } from '@app/pages/StaticPages/AboutUs/AboutUs';
import { Careers } from '@app/pages/StaticPages/Careers/Careers';
import { Investors } from '@app/pages/StaticPages/Investors/Investors';
import { MoreDetailsPage } from '@app/pages/MoreDetailsPage/MoreDetailsPage';
import { Contact } from '@app/pages/Forms/Contact';
import { Subscribe } from '@app/pages/Forms/Subscribe';
import { ValueInclusive } from '@app/pages/MainPage/ValueInclusive/ValueInclusive';
import { RequestQuote } from '@app/pages/Forms/RequestQuote';
import { FriendForward } from '@app/pages/Forms/FriendForward';
import { FriendInvite } from '@app/pages/Forms/FriendInvite';
import { SubscribeSettings } from '@app/pages/Forms/SubscribeSettings';

import '@app/scss/style.scss';
import '@app/scss/text.scss';
import '@app/scss/layout.scss';

export const App: React.FC = () => {
	return (
		<div
			className="font-family_primary text-size_normal root-container"
		>
			<Router>
				<Layout>
					<Routes>
						<Route path={RoutePaths.notFound} element={<NotFound />} />
						<Route path={RoutePaths.mainPage} element={<MainPage />} />

						<Route path={RoutePaths.packagesSearchPage} element={<SearchPackagesPage />} />
						<Route path={RoutePaths.moreDetailsPage} element={<MoreDetailsPage />} />

						<Route path={RoutePaths.generalTerms} element={<GeneralTerms />} />
						<Route path={RoutePaths.privacyPolicy} element={<PrivacyPolicy />} />
						<Route path={RoutePaths.whyOvc} element={<WhyChooseOVC />} />
						<Route path={RoutePaths.passportRequirements} element={<DocumentationRequirements />} />
						<Route path={RoutePaths.faqs} element={<FAQ />} />
						<Route path={RoutePaths.managers} element={<Managers />} />
						<Route path={RoutePaths.investors} element={<Investors />} />
						<Route path={RoutePaths.travelInsurance} element={<TravelInsurance />} />
						<Route path={RoutePaths.aboutPage} element={<AboutUs />} />
						<Route path={RoutePaths.careers} element={<Careers />} />

						<Route path={RoutePaths.requestQuotePage} element={<RequestQuote />} />
						<Route path={RoutePaths.contactPage} element={<Contact />} />
						<Route path={RoutePaths.subscribe} element={<Subscribe />} />
						<Route path={RoutePaths.friendForward} element={<FriendForward />} />
						<Route path={RoutePaths.friendInvite} element={<FriendInvite />} />
						<Route path={RoutePaths.subscribeSettings} element={<SubscribeSettings />} />
						<Route path={RoutePaths.valueInclusivePages} element={<ValueInclusive />} />
						<Route path={RoutePaths.airGateway} element={<AirGateway />} />
						<Route path={RoutePaths.contactPage} element={<Contact />} />
					</Routes>
				</Layout>
			</Router>
		</div>
	);
};
