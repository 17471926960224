import * as React from 'react';

import CoreSelect, { SelectProps as CoreSelectProps } from 'rc-select';
import clsx from 'clsx';

import {
	SelectProps,
	TValue,
} from '@app/components/Selects/SelectProps';
import { useSelect } from '@app/hooks/useSelect';

import '@app/scss/components/select.scss';
import { TIcon } from '@app/objects/Utility';

const renderIcon = (
	Icon: TIcon,
	active: boolean,
) => (
	<div className="content-valign_center">
		<Icon className={clsx('select-component__icon', active && 'select-component__icon_active')} />
	</div>
);

export const Select = <T extends TValue>(props: SelectProps<T>) => {
	const {
		properties,
		ref,
		focus,
		active,
	} = useSelect(props);

	return (
		<div
			className={clsx('select-component-container', props.error && 'select-component-container_error')}
		>
			<div
				className={clsx(
					'select-component font-family_primary font-weight_regular text-size_medium',
					focus && 'select-component_focused',
				)}
				onClick={() => ref.current?.focus()}
			>
				{props.Icon ? renderIcon(props.Icon, active) : null}
				<div className="select-component__control-block is-relative">
					{
						props.multiple
							? (
								<CoreSelect<Array<T>> {...(properties as CoreSelectProps<Array<T>>)}>
									{props.children}
								</CoreSelect>
							)

							: (
								<CoreSelect<T> {...(properties as CoreSelectProps<T>)}>
									{props.children}
								</CoreSelect>
							)
					}
					<div
						className={clsx(
							'select-component__control-block__placeholder',
							(focus || active) && 'select-component__control-block__placeholder_top text-size_extra-small',
						)}
					>
						{props.placeholder}
					</div>
				</div>
			</div>
			<div className="select-component-container__text font-family_primary font-weight_light text-size_extra-small">
				{props.text}
			</div>
		</div>
	);
};
