import * as React from 'react';

import { Nullable } from '@app/objects/Utility';
import { Filters } from '@app/components/Filters/Filters';

import { SearchBar } from '@app/pages/SearchPage/SearchBar/SearchBar';
import { SearchList } from '@app/pages/SearchPage/SearchList/SearchList';

import { List } from '@app/objects/List';

import '@app/scss/pages/search/searchPage.scss';

interface SearchPackagesPageContentProps {
	list: Nullable<List>;

	loading: boolean;
	canReset?: boolean;
	currentPage: number;

	children: React.ReactNode;
	onReset?: () => void;
	onChangePage: (page: number) => void;

	extra?: React.ReactNode;
	searchBarContent?: React.ReactNode;

	sorter: number;
	onChangeSorter: (value: number) => void;
}

const scrollOptions: ScrollToOptions = {
	top: 0,
	left: 0,
	behavior: 'smooth',
};

export const SearchPackagesPageContent: React.FC<SearchPackagesPageContentProps> = (props: SearchPackagesPageContentProps) => {
	React.useEffect(() => window.scrollTo(scrollOptions), [props.currentPage]);
	const [openMobileFilters, setOpenMobileFilters] = React.useState<boolean>(false);
	const [openMobileSorter, setOpenMobileSorter] = React.useState<boolean>(false);

	return (
		<>
			{props.searchBarContent && <SearchBar>{props.searchBarContent}</SearchBar>}
			<div className="col-12" style={{ minHeight: '100%', position: 'relative' }}>
				<div className="layout-container content-valign_center">
					<div className="search-page__container col-12 col-lg-11">
						<Filters
							extra={props.extra}
							onReset={props.onReset}
							canReset={props.canReset}
							open={openMobileFilters}
							onClose={setOpenMobileFilters}
						>
							{props.children}
						</Filters>
						<SearchList
							items={props.list}
							loading={props.loading}
							currentPage={props.currentPage}
							onChangePage={props.onChangePage}
							sorter={props.sorter}
							onChangeSorter={props.onChangeSorter}
							open={openMobileSorter}
							onOpenMobileSorter={setOpenMobileSorter}
							onOpenMobileFilters={setOpenMobileFilters}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
