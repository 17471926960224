import React from 'react';

import { PixelContext } from '@app/services/contexts';

const aId = 21782;
export const PixelTracking: React.FC = () => {
	const { requestQuoteZip } = React.useContext(PixelContext);
	const url = React.useMemo(() => {
		const uqNum = Math.random() * 10000000000000;

		return `https://px.adentifi.com/Pixels?a_id=${aId};cv_1=;cv_2=${requestQuoteZip};cv_3=;p_url=${window.location.href};uq=${uqNum}`;
	}, [window.location.href, requestQuoteZip]);

	return (
		<img
			src={url}
			alt="pixel tracking"
			height={1}
			width={1}
			style={{ display: 'none' }}
		/>
	);
};
