import * as React from 'react';

import { SelectProps as CoreSelectProps, BaseSelectRef } from 'rc-select';
import clsx from 'clsx';

import { OptionProps } from '@app/components/Selects/Option';

import {
	MultipleHooksProps,
	MultipleSelectProps,
	SelectMode,
	SelectProps,
	SingleHooksProps,
	SingleSelectProps,
	TValue,
	UseSelectProps,
} from '@app/components/Selects/SelectProps';

import { Icon } from '@image/icon';

const useMultipleHook = <T extends TValue>(props: MultipleSelectProps<T>): MultipleHooksProps<T> => {
	const [item, setItem] = React.useState<Array<T> | undefined>(() => undefined);
	const hasValue = item && item.length !== 0;

	React.useEffect(() => setItem(props.value), [props.value]);

	const multipleProperties = {
		value: item,
		onChange: (value: Array<T>) => {
			setItem(value);
			props.onChange?.(value);
		},
		mode: SelectMode.Multiple,
	};

	return { multipleProperties, hasValue };
};

const useSingleHook = <T extends TValue>(props: SingleSelectProps<T>): SingleHooksProps<T> => {
	const [item, setItem] = React.useState<T | undefined>(() => undefined);
	const hasValue = item && item !== -1;

	React.useEffect(() => setItem(props.value), [props.value]);

	const singleProperties = {
		value: item,
		onChange: (value: T) => {
			setItem(value);
			props.onChange?.(value);
		},
	};

	return { singleProperties, hasValue };
};

function defaultFilterOption(value: string, option?: OptionProps) {
	const valueString = option?.children as string;

	return valueString.toLowerCase().indexOf(value.toLowerCase()) > -1;
}

type BaseProps<T extends TValue> = Omit<
	CoreSelectProps<T> | CoreSelectProps<Array<T>>,
	keyof SingleHooksProps<T>['singleProperties'] | keyof MultipleSelectProps<T>['multiple']
>;

export const useSelect = <T extends TValue>(props: SelectProps<T>): UseSelectProps<T> => {
	const {
		allowClear = true,
		showSearch = true,
	} = props;
	const ref = React.useRef<BaseSelectRef>(null);
	const [focus, setFocus] = React.useState<boolean>(() => false);
	const onBlur = React.useCallback(() => { setFocus(false); props.onBlur?.(); }, [props.multiple]);
	const onFocus = React.useCallback(() => { setFocus(true); props.onFocus?.(); }, [props.multiple]);

	const baseProperties: BaseProps<T> = {
		open: focus,
		showArrow: true,

		disabled: props.disabled,
		loading: props.loading,
		allowClear,
		showSearch,
		filterOption: props.filterOption ?? defaultFilterOption,

		tabIndex: props.tabIndex,

		prefixCls: 'sc-core',
		className: clsx('font-family_primary font-weight_medium text-size_medium', props.className),
		dropdownClassName: 'select-component__dropdown font-family_primary text-size_small',
		style: props.style,

		onSelect: props.onSelect,
		onDeselect: props.onDeselect,
		onSearch: props.onSearch,
		onPopupScroll: props.onPopupScroll,
		onBlur,
		onFocus,

		ref,
		notFoundContent: 'No Content',

		inputIcon: <Icon.ChevronDown className="select-component__icon" />,
		clearIcon: <Icon.Clear className="select-component__icon" />,
		menuItemSelectedIcon: <Icon.Check className="select-component__icon" />,
	};

	if (props.multiple) {
		const data: MultipleSelectProps<T> = {
			value: props.value,
			onChange: props.onChange,
			multiple: true,
		};
		const { multipleProperties, hasValue } = useMultipleHook(data);
		const active = hasValue || focus;
		const properties: CoreSelectProps<Array<T>> = { ...baseProperties, ...multipleProperties };

		return {
			properties,
			ref,
			focus,
			active,
		};
	}

	const data: SingleSelectProps<T> = {
		value: props.value,
		onChange: props.onChange,
	};
	const { singleProperties, hasValue } = useSingleHook(data);
	const properties: CoreSelectProps<T> = { ...baseProperties, ...singleProperties };
	const isValidValue = hasValue || (Number(hasValue) !== -1 && hasValue !== null && hasValue !== undefined) ? true : hasValue;
	const active = isValidValue || focus;

	return {
		properties,
		ref,
		focus,
		active,
	};
};
